import React from "react"
import Figure from "./figure"
import { Img } from "gatsby-source-dek-wp"

function figureTransformer(node, i, { classList, children }) {
  // transform all wp-block-images and gallery images to expandable figure
  if (node.name === "figure" && !classList.includes("vg-wort")) {
    const parentClassList = (node.parent?.attribs?.class || "").split(" ")
    const isGalleryItem = parentClassList.includes("blocks-gallery-item")
    const butNotFilebird = !(node.parent?.parent?.attribs?.class || "")
      .split(" ")
      .includes("filebird-block-filebird-gallery")
    if (
      (classList.includes("wp-block-image") && !classList.includes("logo")) ||
      (isGalleryItem && butNotFilebird)
    ) {
      const img = children.find((c) => c.type === "img" || c.type === Img)
      const figcaption = children.find((c) => c.type === "figcaption")
      return (
        <Figure
          className={classList.join(" ")}
          img={img}
          figcaption={figcaption}
        />
      )
    }
  }
}

export default figureTransformer
