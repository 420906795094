import React from "react"
import ContentOverlay from "./content-overlay"
import ScrollVideo from "./scroll-video"
import { isElement } from "gatsby-source-dek-wp"

function scrollVideoTransformer(node, i, { classList, children: _children }) {
  if (
    classList.includes(
      "wp-block-dekoder-custom-blocks-background-changer-gallery"
    )
  ) {
    const children = React.Children.toArray(_children).filter(isElement)
    const ul = (children || []).find((c) => c.type === "ul")
 
    const imgProps = (ul?.props?.children || [])
      .map((c) => c.props.children?.props?.children?.props)
      .map((p) => {
        const { src, sizes, srcSet } = p
        return { src, sizes, srcSet }
      })
      .sort(sortBySrcFilename)

    const div = (children || []).find((c) => c.type === "div")
    const divChilds =
      React.Children.toArray(div?.props?.children?.props?.children) || []
    const overlays = divChilds.map((o, i) => (
      <ContentOverlay key={i} {...o.props} />
    ))
    return (
      <ScrollVideo
        imgProps={imgProps}
        overlays={overlays}
        offloaded={node.attribs.offloaded === "true"}
        startFrame={node.attribs.startframe * 1}
        endFrame={node.attribs.endframe * 1}
      />
    )
  }
}

export default scrollVideoTransformer

function sortBySrcFilename(a, b) {
  const aSplits = a.src.split("/")
  const aFN = aSplits[aSplits.length - 1]
  const bSplits = b.src.split("/")
  const bFN = bSplits[bSplits.length - 1]
  return aFN < bFN ? -1 : aFN > bFN ? 1 : 0
}
